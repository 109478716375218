import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { URL_DATE_FORMAT } from '../constants/misc';
import { SnackBarService } from './snack-bar.service';
import { areRangesSame } from '../utils/utils';

export interface IPropertyFiltersDateRange {
  start: dayjs.Dayjs | null;
  end: dayjs.Dayjs | null;
}

export interface IPropertyFiltersPriceRange {
  min?: number | null;
  max?: number | null;
}

export type filterType = 'dateRange' | 'priceRange' | 'guests' | 'bedrooms' | 'bathrooms';

export interface IPropertyFilters {
  dateRange: IPropertyFiltersDateRange;
  priceRange: IPropertyFiltersPriceRange;
  guests: number;
  bedrooms: number;
  bathrooms: number;
}

@Injectable({
  providedIn: 'root'
})
export class PropertyFiltersService implements OnDestroy {
  private _currentFilters$ = new BehaviorSubject<IPropertyFilters>(PropertyFiltersService.getEmptyFilters());
  public get currentFilters$(): Observable<IPropertyFilters> { return this._currentFilters$.asObservable(); }

  private _isFilterPanelOpen$ = new BehaviorSubject<boolean>(false);
  public get isFilterPanelOpen$(): Observable<boolean> { return this._isFilterPanelOpen$.asObservable(); }

  private _unsubscribe = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private snackBar: SnackBarService
  ) {
    this.route.queryParamMap
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(queryParams => {
        const newFilters = this.getFromParams(queryParams);

        this.applyFilters(newFilters);
      });
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public closeFiltersPanel() {
    this._isFilterPanelOpen$.next(false);
  }

  public toggleFiltersPanel() {
    this._isFilterPanelOpen$.next(!this._isFilterPanelOpen$.value);
  }

  public static getEmptyFilters(): IPropertyFilters {
    return {
      dateRange: {
        start: null,
        end: null
      },
      priceRange: {
        min: null,
        max: null
      },
      guests: 1,
      bathrooms: 0,
      bedrooms: 0
    };
  }

  public static isDefaultFilter(type: filterType, filters?: IPropertyFilters | null): boolean {
    if (!filters) {
      return true;
    }

    switch (type) {
      case 'dateRange': return filters.dateRange?.start == null && filters.dateRange?.end == null;
      case 'priceRange': return filters.priceRange?.min == null && filters.priceRange?.max == null;
      case 'guests': return filters.guests === null || filters.guests === undefined || filters.guests === 1;
      case 'bedrooms': return filters.bedrooms === null || filters.bedrooms === undefined || filters.bedrooms === 0;
      case 'bathrooms': return filters.bathrooms === null || filters.bathrooms === undefined || filters.bathrooms === 0;
    }
  }

  public static areDefaultFilters(filters?: IPropertyFilters | null): boolean {
    return ['dateRange', 'priceRange', 'guests', 'bedrooms', 'bathrooms'].every(type => this.isDefaultFilter(type as filterType, filters));
  }

  public static areEqual(current: IPropertyFilters, newFilters: IPropertyFilters): boolean {
    if (!areRangesSame(current.dateRange, newFilters.dateRange)) {
      return false;
    }

    if (current.priceRange.min !== newFilters.priceRange.min) {
      return false;
    }

    if (current.priceRange.max !== newFilters.priceRange.max) {
      return false;
    }

    if (current.guests !== newFilters.guests) {
      return false;
    }

    if (current.bathrooms !== newFilters.bathrooms) {
      return false;
    }

    if (current.bedrooms !== newFilters.bedrooms) {
      return false;
    }

    return true;
  }

  public applyFilters(newFilters: IPropertyFilters | null) {
    const filters = newFilters ?? PropertyFiltersService.getEmptyFilters();

    if (!PropertyFiltersService.areEqual(this._currentFilters$.value, filters)) {
      const url = this.router.createUrlTree([], {
        relativeTo: this.route, queryParams: {
          checkIn: filters.dateRange.start ? filters.dateRange.start.format(URL_DATE_FORMAT) : '',
          checkOut: filters.dateRange.end ? filters.dateRange.end.format(URL_DATE_FORMAT) : '',
          min: filters.priceRange.min ?? '',
          max: filters.priceRange.max ?? '',
          guests: filters.guests === 1 ? '' : filters.guests,
          bathrooms: filters.bathrooms || '',
          bedrooms: filters.bedrooms || ''
        }
      }).toString();

      this.location.replaceState(url);
      this._currentFilters$.next(filters);
    }
  }

  private getFromParams(params: ParamMap): IPropertyFilters {
    const filters = PropertyFiltersService.getEmptyFilters();

    if (params.get('checkIn')) {
      const start = dayjs(params.get('checkIn'), URL_DATE_FORMAT);

      if (!start.isValid()) {
        this.snackBar.showError('Invalid check-in date.');
      } else if (start.isBefore(dayjs(), 'day')) {
        this.snackBar.showError('Check-in cannot be in the past.');
      } else {
        filters.dateRange.start = start;
      }
    }

    if (params.get('checkOut')) {
      const end = dayjs(params.get('checkOut'), URL_DATE_FORMAT);

      if (!end.isValid()) {
        this.snackBar.showError('Invalid check-out date.');
      } else if (filters.dateRange.start && end.isSameOrBefore(filters.dateRange.start, 'day')) {
        this.snackBar.showError('At least one night is required between check-in and check-out.');
      } else {
        filters.dateRange.end = end;
      }
    }

    if (params.get('min')) {
      const min = parseInt(params.get('min')!, 10);

      if (isNaN(min) || min < 0) {
        this.snackBar.showError('Invalid minimum price.');
      } else {
        filters.priceRange.min = min;
      }
    }

    if (params.get('max')) {
      const max = parseInt(params.get('max')!, 10);

      if (isNaN(max) || max < 0 || (filters.priceRange.min != null && max < filters.priceRange.min)) {
        this.snackBar.showError('Invalid maximum price.');
      } else {
        filters.priceRange.max = max;
      }
    }

    if (params.get('guests')) {
      const guests = parseInt(params.get('guests')!, 10);

      if (isNaN(guests) || guests < 1) {
        this.snackBar.showError('Invalid number of guests.');
      } else {
        filters.guests = guests;
      }
    }

    if (params.get('bedrooms')) {
      const bedrooms = parseInt(params.get('bedrooms')!, 10);

      if (isNaN(bedrooms) || bedrooms < 0) {
        this.snackBar.showError('Invalid number of bedrooms.');
      } else {
        filters.bedrooms = bedrooms;
      }
    }

    if (params.get('bathrooms')) {
      const bathrooms = parseInt(params.get('bathrooms')!, 10);

      if (isNaN(bathrooms) || bathrooms < 0) {
        this.snackBar.showError('Invalid number of bathrooms.');
      } else {
        filters.bathrooms = bathrooms;
      }
    }

    return filters;
  }


}
